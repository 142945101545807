import "../../styles/sofidel/index.scss";

import {SmartComponentManager} from "smart-component-js";
import DefaultCtrl from "./controllers/DefaultCtrl";
import $ from 'jquery';

import HeaderComponent from "./components/HeaderComponent";
import DigitalRewardsComponent from "./components/DigitalRewardsComponent";
import ReceiptComponent from "./components/ReceiptComponent";
import PrizesRequestComponent from "./components/PrizesRequestComponent";
import RewardListComponent from "./components/RewardListComponent";
import PrizeRequestConfirmComponent from "./components/PrizeRequestConfirmComponent";
import FooterComponent from "./components/FooterComponent";
import OnBoardingComponent from "./components/OnBoardingComponent";
import PlayCodeComponent from "./components/PlayCodeComponent";
import UploadReceiptFixHuComponent from "./components/UploadReceiptFixHuComponent";
import YouWinComponent from "./components/YouWinComponent";
import InstantWinComponent from "./components/InstantWinComponent";
import RewardFormComponent from "./components/RewardFormComponent";
import ProductCarouselComponent from "./components/ProductCarouselComponent";
import HomeComponent from "./components/HomeComponent";
import MyActivitiesComponent from "./components/MyActivitiesComponent";
import MissionModalComponent from "./components/MissionModalComponent";
import CustomClickComponent from "./components/CustomClickComponent";
import PostRegistrationComponent from "./components/PostRegistrationComponent";
import TrackDownloadCouponComponent from "./components/TrackDownloadCouponComponent";

SmartComponentManager.registerComponents({
    DefaultCtrl,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MyActivitiesComponent,
    DigitalRewardsComponent,
    ProductCarouselComponent,
    ReceiptComponent,
    PrizesRequestComponent,
    RewardListComponent,
    PrizeRequestConfirmComponent,
    InstantWinComponent,
    RewardFormComponent,
    OnBoardingComponent,
    PlayCodeComponent,
    UploadReceiptFixHuComponent,
    YouWinComponent,
    MissionModalComponent,
    PostRegistrationComponent,
    CustomClickComponent,
    TrackDownloadCouponComponent
});

$(document).ready(() => {
    let $body = $('body [component="DefaultCtrl"]');
    SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
});

