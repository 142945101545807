import {SmartComponent} from "smart-component-js";

class TrackDownloadCouponComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }

    sendTrackingDownload() {
            try {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "content_download", category: "DOWNLOAD", action: "COUPON", label: location.href
                });
                youserxp.tracker.getInstance().send('EVENT', {
                    eventCategory: "DOWNLOAD", eventAction: "COUPON", eventLabel: location.href
                });
            } catch (e) {
                console.log("Tracking: " + e);
            }
    }

    init() {
         document.addEventListener('yxp-loyalty-coupon-download-ok', (e) => this.sendTrackingDownload());
    }

}

export default TrackDownloadCouponComponent;