import {SmartComponent} from "smart-component-js";

class DefaultCtrl extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.el = element;
        this.init();
    }

    smoothAnchorScroll() {
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const hrefAttribute = this.getAttribute('href')?.replace('#', '');
                const element = document.getElementById(hrefAttribute) || document.querySelector(`[data-anchor="${hrefAttribute}"]`);
                element?.scrollIntoView({behavior: 'smooth'});
            });
        });
    }


    addShowPasswordButton() {
        const passwordInputs = document.querySelectorAll('input[type="password"]');
        passwordInputs.forEach((input) => {
            const wrapper = input.parentElement;
            wrapper.insertAdjacentHTML('beforeend', '<button type="button" class="visibility-icon"></button>');
            this.addPasswordListener(wrapper, input);
        });
    }


    addPasswordListener(wrapper, input) {
        wrapper.querySelector('.visibility-icon').addEventListener('click', () => {
            const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
            input.setAttribute('type', type);
            wrapper.classList.toggle('password-visible');
        });
    }





    init() {
        this.loadChildComponents(this);
        this.smoothAnchorScroll();

        document.addEventListener("yxp-form-loaded", () => {
            this.loadChildComponents(this);
            this.addShowPasswordButton();
        });



    }
}

export default DefaultCtrl;
